import React, { useContext, useState } from "react";
import "./MobileHeader.scss";
import { Link, NavLink } from "react-router-dom";
import logoImg from "../../assets/images/logo-web.png"
import { Squeeze as Hamburger } from "hamburger-react";
import menuBtn1 from '../../assets/images/menu-1.png';
import menuBtn2 from '../../assets/images/menu-2.png';
import menuBtn3 from '../../assets/images/menu-3.png';
import menuBtn4 from '../../assets/images/menu-4.png';
import profileBtn from '../../assets/images/login.png'
import { useAuth } from "../../context/AuthContext";

const MobileHeader = () => {
  const [open, setOpen] = useState(false);
  const { user } = useAuth(); // ✅ Corrección aquí
  const ubication = window.location.pathname;

  const menuItems = [
    {
      label: "nuestros cafés", imgUrl: menuBtn1, url: "/nuestroscafes"
    },
    {
      label: "¿quiénes somos?", imgUrl: menuBtn2, url: "/quienessomos"
    },
    {
      label: "accesorios pro", imgUrl: menuBtn3, url: "/accesorios"
    },
    {
      label: "contacto", imgUrl: menuBtn4, url: "/contacto"
    },
  ];

  const handleLinkClick = () => {
    setOpen(false);
  };

  return (
    <div className="header-mobile">
      <div className="header-mobile__block">
        <div className="header-mobile__menu">
          <Hamburger
            toggled={open}
            toggle={setOpen}
            easing="ease-in"
            rounded
            hideOutline={false}
            onClick={() => setOpen(!open)}
            color="#ffffff"
          ></Hamburger>
        </div>
        <Link to='/'>
          <img className="header-mobile__logo" src={logoImg} alt="logo-aroma-king" />
        </Link>



      </div>

      <nav className={`header-nav ${!open ? 'slide-in' : 'slide-out'}`}>
        <div className="header-nav__sections">

          {menuItems.map((links, index) => {
            return (
              <NavLink
                key={index}
                to={links.url}
                className="header-mobile__fieldset"
                onClick={handleLinkClick}
              >
                <img className="header-mobile__icons" src={links.imgUrl} alt={links.label} />
                <p className="header-mobile__links">{links.label}</p>
              </NavLink>
            );
          })}
         {/* <Link onClick={handleLinkClick} className="header-mobile__fieldset" to={user ? "/perfil" : "/login"}>
            <img src={profileBtn} className="header-mobile__icons" alt="" />
            <p className="header-mobile__links">{user ? 'Mi Perfil' : 'Login'}</p>
          </Link> */}
        </div>

      </nav>

    </div>
  )
}

export default MobileHeader