import './App.scss';
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import HomePage from './pages/HomePage/HomePage';
import Header from './components/Header/Header';
import ContactPage from './pages/ContactPage/ContactPage';
import EventsPage from './pages/EventsPage/EventsPage';
import TastingPage from './pages/TastingPage/TastingPage';
import ButtonEvents from './components/ButtonEvents/ButtonEvents';
import ButtonBuy from './components/ButtonBuy/ButtonBuy';
import RegisterPage from './pages/RegisterPage/RegisterPage';
import LoginPage from './pages/LoginPage/LoginPage';
import AboutUs from './pages/AboutUs/AboutUs';
import OurCoffees from './pages/OurCoffees/OurCoffees';
import PrivateMeeting from './pages/PrivateMeeting/PrivateMeeting';
import ConfirmationScreen from './pages/ConfirmationScreen/ConfirmationScreen';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import AccesoriesPro from './pages/AccesoriesPro/AccesoriesPro';

const pageVariants = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0, transition: { duration: 0.4 } },
  exit: { opacity: 0, y: -20, transition: { duration: 0.4 } },
};

function Layout() {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={location.pathname}
        initial="initial"
        animate="animate"
        exit="exit"
        variants={pageVariants}
      >
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<HomePage />} />
          <Route path="/contacto" element={<ContactPage />} />
          <Route path='/eventos' element={<EventsPage />} />
          <Route path='/eventos/catas' element={<TastingPage />} />
          <Route path='/quienessomos' element={<AboutUs />} />
          <Route path='/registro' element={<RegisterPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path='/nuestroscafes' element={<OurCoffees />} />
          <Route path='/eventos/reunion' element={<PrivateMeeting />} />
          <Route path='/eventos/reunion/confirmacion' element={<ConfirmationScreen />} />
          <Route path="/perfil" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
          <Route path='/accesorios' element={<AccesoriesPro />} />
        </Routes>
      </motion.div>
    </AnimatePresence>
  );
}

function App() {
  return (
    <Router>
      <div className="app">
        <Header />
        <Layout />
        <ButtonEvents />
        <ButtonBuy />
      </div>
    </Router>
  );
}

export default App;
