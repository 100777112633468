import React from 'react';
import './AccesoriesPro.scss';

const AccesoriesPro = () => {
    return (
        <div className='accesories-pro'>
             <h2>Accesorios Pro</h2>
                <div className="accesories-pro__block">
                    <p>Proximamente.....</p>
                </div>
        </div>
    )
}

export default AccesoriesPro