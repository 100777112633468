import React, { useEffect, useState } from 'react';

const CountDown = () => {
  const [timeLeft, setTimeLeft] = useState('48:00:00');

  useEffect(() => {
    // Intentar obtener la marca de tiempo de inicio desde localStorage
    let startTime = localStorage.getItem('mistercoffee_timer_start');

    if (!startTime) {
      // Si no existe, establecer la marca de tiempo actual como inicio
      startTime = new Date().getTime();
      localStorage.setItem('mistercoffee_timer_start', startTime);
    }

    // Calcular el tiempo objetivo sumando 48 horas al tiempo de inicio
    const targetTime = parseInt(startTime) + 48 * 60 * 60 * 1000;

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetTime - now;

      if (distance <= 0) {
        clearInterval(interval);
        setTimeLeft('00:00:00');
        localStorage.removeItem('mistercoffee_timer_start'); // Opcional: limpiar localStorage cuando el contador llegue a cero
        return;
      }

      const hours = String(Math.floor((distance / (1000 * 60 * 60)))).padStart(2, '0');
      const minutes = String(Math.floor((distance / (1000 * 60)) % 60)).padStart(2, '0');
      const seconds = String(Math.floor((distance / 1000) % 60)).padStart(2, '0');

      setTimeLeft(`${hours}:${minutes}:${seconds}`);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{
      fontSize: '2rem',
      fontWeight: 'bold',
      color: 'white',
      textShadow: '1px 1px 2px rgba(24, 24, 24, 0.5)',
    }}>
      {timeLeft}
    </div>
  );
};

export default CountDown;
