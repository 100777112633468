import React from 'react'
import { Link } from 'react-router-dom';
import eventsBtn from '../../assets/images/eventos.png';
import './ButtonEvents.scss'

const ButtonEvents = () => {
    return (
        <div className='button-events'>
            <Link to="/eventos">
                <img className='button-events__btn' src={eventsBtn} alt='eventos' />
            </Link>
        </div>

    )
}

export default ButtonEvents