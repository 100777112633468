// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyApeSn8TG6eLyi8_vL7DkhnhstlrXSmaL4",
  authDomain: "mister-coffee-763ae.firebaseapp.com",
  projectId: "mister-coffee-763ae",
  storageBucket: "mister-coffee-763ae.firebasestorage.app",
  messagingSenderId: "509437818681",
  appId: "1:509437818681:web:c3dc65a762f6c254df5ae8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);