import React from 'react';
import './AboutUs.scss';
import imgBackground from '../../assets/images/aboutus-1.jpg';

const AboutUs = () => {
    return (
        <div className='about-us'>
            <div className="about-us__img"></div>
         
            <div className="about-us__block">
                <h2 className="about-us__title">¿Quiénes somos?</h2>
                <div className="about-us__paragraph">
                    Somos una empresa familiar que nace de la pasión profunda por el café y la nutrición. Seleccionamos cuidadosamente granos 100% arábica provenientes de fincas exclusivas de Colombia y de los países más prestigiosos del trópico cafetero mundial, esas tierras legendarias que presumen de cultivar el café más exquisito del planeta.

                </div>
                <div className="about-us__paragraph">

                    Nuestro viaje comenzó con un sueño claro: descubrir el café perfecto, aquel que no solo deleita el paladar, sino que también aporta bienestar. Tras años recorriendo el mundo, aprendiendo y experimentando, hemos creado recetas únicas y exclusivas, fruto de una minuciosa selección de los granos más excepcionales de cada región, fusionando sus sabores en armonías inigualables. Esta pasión por la excelencia ha llevado a muchos de nuestros amigos y clientes a definir a Mr Coffee como "el café de los Dioses".
                </div>
                <div className="about-us__paragraph">
                    En Mr Coffee estamos constantemente innovando y explorando nuevos ratios y métodos de extracción. Actualmente, trabajamos en una técnica revolucionaria y única que llevará la experiencia del café a un nivel nunca antes visto, con la visión de convertirla en una referencia mundial, tal como ocurrió con el Espresso (Italia, 1901) o la Prensa Francesa (Francia, 1929).
                </div>
                <div className="about-us__paragraph">
                    Cada sorbo de Mr Coffee te transporta a un universo sensorial único e irrepetible, fruto de una dedicación absoluta y un compromiso inquebrantable con la calidad. Porque Mr Coffee no es solo café: es nuestra pasión convertida en arte por un artista que pronto descubrirás
                </div>
            </div>
        </div>
    )
}

export default AboutUs