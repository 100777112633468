import React from 'react';
import buyBtn from '../../assets/images/comprar.png';
import { Link } from 'react-router-dom';


const ButtonBuy = () => {
    return (
        <div className='button-buy'>
            <Link to="/nuestroscafes">
                <img className='button-buy__btn' src={buyBtn} alt='comprar' />
            </Link>
        </div>


    )
}

export default ButtonBuy