import React from 'react';
import './ContactPage.scss';
import instaBtn from '../../assets/icons/insta.png';
import ubicationBtn from '../../assets/icons/ubi.png';
import emailBtn from '../../assets/icons/email.png';

const ContactPage = () => {
    return (
        <div className='contact__block'>
             <h2 className="contact__title">INFORMACIÓN DE CONTACTO:</h2>
            <div className="contact__info">
               
                <div className="contact__fieldset">
                  <img className='contact__icon' src={emailBtn} alt="email-btn" />
                  <p className="contact__link">info@mistercoffee.es</p>
                </div>
                <div className="contact__fieldset">
                <img className='contact__icon' src={instaBtn} alt="instagram-btn" />
                <p className="contact__link">Instagram:</p>
                  <p className="contact__link">@mistercoffee.es</p>
                </div>
                <div className="contact__fieldset">
                <img className='contact__icon' src={ubicationBtn} alt="ubication" />
                  <p className="contact__link">Calle Comedias, 7 Antequera Málaga - 29200</p>
                </div>
            </div>
        </div>
    )
}

export default ContactPage