import React from 'react';
import './OurCoffees.scss';
import timeClock from '../../assets/images/clock.png'
import CountDown from '../../components/CountDown/CowntDown';

const OurCoffees = () => {
  return (
    <div className='our-coffees'>
        <h2 className="our-coffees__title">Nuestros Cafés:</h2>
        <div className="our-coffees__block">
          <img className='our-coffees__img' src={timeClock} alt="" />
          <CountDown/>
        </div>
    </div>
  )
}

export default OurCoffees